import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4425de1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switcher flex items-center" }
const _hoisted_2 = { class: "fw500" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.input.placeholder), 1),
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      name: "switcher",
      id: _ctx.input.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleSwitcher')))
    }, null, 40, _hoisted_3), [
      [_vModelCheckbox, _ctx.data[_ctx.input.id]]
    ]),
    _createElementVNode("label", {
      for: _ctx.input.id
    }, null, 8, _hoisted_4)
  ]))
}