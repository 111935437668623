
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['toggleSwitcher'],
  name: "ModalSwitcher",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
});
